export const environment = {
  production: true,
  apibaseUrl: 'https://admin.stg.omniorder.com.au',
  signUpSignInAuthority:"https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
  forgotPasswordAuthority:"https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1A_PASSWORDRESET",
  editProfileAuthority:"https://omniorderb2c.b2clogin.com/omniorderb2c.onmicrosoft.com/B2C_1A_PROFILEEDIT",
  authorityDomain:"omniorderb2c.b2clogin.com",
  clientId:"12ac1945-56ba-4522-9f87-14d4c74d55a1",
  scope:"https://omniorderb2c.onmicrosoft.com/bc403961-cbd1-4554-8186-bbb3743ce090/access_as_user",
  postLogoutRedirectUri:"https://www.omniorder.com.au",
};
